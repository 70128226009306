<template>
  <div>
    <div v-if="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex justify-between">
          <h3 class="box-title">Market Files</h3>
          <div class="flex align-items-center">
            <span class="text-bold text-danger">{{ searching }}</span>
            <input v-model="keyword" aria-label="search" class="form-control ml-8" placeholder="Search..." type="text"
              @keyup="search()" />
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th>ID</th>
                <th>Merchant</th>
                <th>Name</th>
                <th>File</th>
                <th>Size</th>
                <th>MimeType</th>
                <th>Created On</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="files.length > 0">
                <tr v-for="(file, index) in files" :key="index">
                  <td>{{ file.ID }}</td>
                  <td>{{ file.ShopTitle }}</td>
                  <td>{{ file.Name }}</td>
                  <td>
                    <silent-box :image="generateImageUrl(file)"></silent-box>
                  </td>
                  <td>{{ file.Size }}</td>
                  <td>{{ file.MimeType }}</td>
                  <td>{{ formatDate(file.CreatedOn) }}</td>
                  <td class="pr-16">
                    <a v-tooltip="{ content: 'Delete File', trigger: 'click focus hover' }" class="nav-action danger"
                      @click="deleteFile(file, index)">
                      <svg class="w-24 h-24" fill="none" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                        <path
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16">
                        </path>
                      </svg>
                    </a>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="8">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pagination from "@/components/Pagination";
import { START_LOADING, STOP_LOADING } from "@/store/keys";
import { formatDate, saveNotification } from "@/lib/utils";

export default {
  name: "MarketFiles",
  components: {
    Pagination,
  },
  data() {
    return {
      files: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      keyword: "",
      searching: "",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
    ]),
  },
  methods: {
    updatePage(page) {
      this.getPaginatedMarketFiles(page);
    },
    search() {
      this.searching = "Searching...";
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.expandedRowIndex = 0;
        this.getPaginatedMarketFiles(1);
      }, 500);
    },
    getPaginatedMarketFiles(page = 1) {
      this.$store.commit(START_LOADING);
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMarketFiles`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: this.keyword ? this.keyword.trim() : "",
          limit: Number.parseInt(this.meta.limit),
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.files = response.data.data.files;
          this.meta = response.data.data.meta;
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    formatDate(val) {
      return formatDate(val);
    },
    generateImageUrl(record) {
      return {
        src: `https://app.cointopay.com/M_Images?ID=${record.ID}`,
        thumbnailHeight: "60px",
      };
    },
    deleteFile(file, index) {
      this.$swal.fire({
        title: "Market Files",
        text: "Are you sure, want to delete this file",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          const apiBaseUrl = process.env.VUE_APP_API_URL;
          this.$http.get(`${apiBaseUrl}/MarketAPI`, {
            params: {
              del: file.ID,
            },
          }).then(response => {
            if (response.status === 200) {
              this.files.splice(index, 1);
              this.meta = Object.assign({}, this.meta, {
                total: this.meta.total - 1,
              });
              this.$toast.fire("", "File deleted successfully", "success");
              saveNotification("File deleted successfully");
            }
          }).catch(error => {
            console.log(error);
          });
        }
      });
    },
  },
  mounted() {
    this.getPaginatedMarketFiles();
  },
  beforeDestroy() {
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>
